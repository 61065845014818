import { useAppContext } from 'AppContext';
import { useLazyQuery } from '@apollo/client';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Card } from 'components/ui/card';
import { Switch } from 'components/ui/switch';
import LoaderComponent from 'components/LoaderComponent';
import ProfileAvatar from 'components/ProfileAvatar';
import { useSignatureId } from 'hooks/signature';
import {
  GET_ANALYTICS,
  GET_ANALYTICS_FOLDER,
  GET_ANALYTICS_LINKS,
} from 'modules/Signature/graphql/Queries';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'hooks/theme';
import {
  useGetCurrentWorkspace,
  useToggleUtagsForWorkspace,
  useWorkspaceId,
} from 'hooks/workspace';
import { cn } from 'lib/utils';
import Overview from './components/Overview';
import Clicks from './components/Clicks';
import Region from './components/Region';
import Browser from './components/Browser';
import OperatingSystem from './components/OperatingSystem';
import Device from './components/Device';
import SignatureAnalyticsCard from '../WorkspaceAnalytics/components/SignatureAnalyticsCard';

function Analytics({ customSignatureId = null, hideTitle = false }) {
  const {
    state: { isTablet },
  } = useAppContext();
  const { color } = useTheme();
  const { signatureId: signatureIdParams } = useSignatureId();
  const signatureId = customSignatureId || signatureIdParams;

  const [analyticsData, setAnalyticsData] = useState();
  const [analytics, setAnalytics] = useState();
  const [analyticsLinks, setAnalyticsLinks] = useState('');
  const [analyticsLinksData, setAnalyticsLinksData] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  const { workspace } = useGetCurrentWorkspace();
  const utagEnabled = workspace?.utagEnabled;
  const { workspaceId } = useWorkspaceId();
  const [toggleUtagsForWorkspace, { loadingutag, error, data }] =
    useToggleUtagsForWorkspace();

  const [getAnalytics, { loading: analyticsLoading }] = useLazyQuery(
    GET_ANALYTICS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setAnalytics(res?.analyticLinks?.data);
        setAnalyticsLinks(res?.analyticLinks?.data?.[0]?.refId);
      },
    },
  );
  const [getAnalyticsLinks, { loading: getLinksLoading }] = useLazyQuery(
    GET_ANALYTICS_LINKS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setAnalyticsLinksData(res?.linkAnalytics);
      },
    },
  );

  const [getAnalyticsFolder, { loading }] = useLazyQuery(GET_ANALYTICS_FOLDER, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setAnalyticsData(res?.folderAnalytics);
    },
  });

  useEffect(() => {
    if (signatureId) {
      getAnalytics({
        variables: {
          where: {
            signatureId,
          },
        },
      });
      getAnalyticsFolder({
        variables: {
          where: {
            signatureId,
          },
          filter: {},
        },
      });
    }
  }, [signatureId]);

  useEffect(() => {
    if (analyticsLinks) {
      getAnalyticsLinks({
        variables: {
          where: {
            refId: analyticsLinks,
          },
          filter: {},
        },
      });
    }
  }, [analyticsLinks]);

  if (analyticsLoading) {
    return <LoaderComponent setWidth="100%" />;
  }

  const handleToggle = (checked) => {
    toggleUtagsForWorkspace({
      variables: {
        where: {
          workspaceId,
          utagEnabled: checked,
        },
      },
    });
  };
  return (
    <div className="">
      {!utagEnabled && (
        <SignatureAnalyticsCard
          handleToggle={handleToggle}
          workspace={workspace}
          loading={loading}
        />
      )}

      {utagEnabled && (
        <div className="bg-primary-foreground px-4">
          {' '}
          {!hideTitle && (
            <header className="h-[102px] flex items-center justify-between ">
              <h1 className="font-primary text-h6 font-bold text-neutral-1000">
                Analytics
              </h1>
              {/* {!isTablet && (
            <div className="flex items-center gap-4">
              <div className="flex gap-4">
                <ProfileAvatar />
              </div>
            </div>
          )} */}
            </header>
          )}
          <div>
            <div className="text-medium-base font-primary">
              <div>
                <Overview
                  loading={loading}
                  analyticsData={analyticsData}
                  getAnalyticsFolder={getAnalyticsFolder}
                  getAnalyticsLinks={getAnalyticsLinks}
                  analyticsLinks={analyticsLinks}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />

                <Card className="my-[24px] shadow-none">
                  <div className="flex items-center justify-start">
                    <div className="flex items-center  justify-center font-primary text-h7 px-3 ">
                      Select Link
                    </div>
                    <div className="p-3 w-[500px]">
                      <Select
                        defaultValue={analytics?.[0]?.refId}
                        onValueChange={(value) => {
                          setAnalyticsLinks(value);
                        }}
                        className="w-full"
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select link" />
                        </SelectTrigger>
                        <SelectContent>
                          {analytics?.map(({ refId, destinationLink }) => (
                            <SelectItem value={refId} key={refId}>
                              {destinationLink?.replace('mailto:', '')}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </Card>

                <div className="grid grid-cols-2 gap-[24px] lg:grid-cols-1">
                  <Clicks
                    color={color}
                    hideCard
                    analyticsData={analyticsData}
                    getLinksLoading={getLinksLoading}
                    analyticsLinksData={analyticsLinksData}
                  />
                  <Region
                    color={color}
                    hideCard
                    hideTable
                    analyticsData={analyticsData}
                    getLinksLoading={getLinksLoading}
                    analyticsLinksData={analyticsLinksData}
                  />
                  <Browser
                    hideCard
                    color={color}
                    analyticsData={analyticsData}
                    getLinksLoading={getLinksLoading}
                    analyticsLinksData={analyticsLinksData}
                  />
                  <OperatingSystem
                    hideCard
                    color={color}
                    analyticsData={analyticsData}
                    getLinksLoading={getLinksLoading}
                    analyticsLinksData={analyticsLinksData}
                  />
                  <Device
                    hideCard
                    color={color}
                    analyticsData={analyticsData}
                    analyticsLinksData={analyticsLinksData}
                    getLinksLoading={getLinksLoading}
                  />
                  <Region
                    color={color}
                    hideCard
                    hideGraph
                    analyticsData={analyticsData}
                    analyticsLinksData={analyticsLinksData}
                    getLinksLoading={getLinksLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Analytics;
