import React from 'react';
import { Switch } from 'components/ui/switch';
import { cn } from 'lib/utils';

const SignatureAnalyticsCard = ({ handleToggle, workspace, loading }) => (
  <div
    className={cn(
      'w-full h-[calc(100vh_-_350px)] flex justify-center items-center bg-primary-foreground',
    )}
  >
    <div
      className={cn('max-w-xs p-6 bg-white rounded-lg text-center bg-white-0')}
    >
      <h1 className="text-2xl font-bold m-0 pt-2">Signature Analytics</h1>
      <p>
        Enable signature analytics for this workspace to track and measure the
        impact of all your signature links.
      </p>

      <Switch onCheckedChange={handleToggle} checked={workspace?.utagEnabled} />

      {/* Date Range Picker */}
      {loading && <p className="mt-2 text-gray-600">Toggling Utag...</p>}
      {/* {data && (
        <p className="mt-2 text-green-600">
          {data?.toggleUtagsForWorkspace?.message}
        </p>
      )} */}
    </div>
  </div>
);

export default SignatureAnalyticsCard;
